import React, { useCallback, useState } from "react";
import Link from "next/link";
import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
} from "@ant-design/icons";
import SocialLink from "components/SocialLink/SocialLink";
import ContactForm from "components/ContactForm/ContactForm";
import { useFetchUser, useMutationBundle } from "source/hooks";
import { useForm } from "react-hook-form";
import { NewsletterSubmission } from "$gql/mutations/general/NewsletterSubmission.gen";
import styles from "./FooterV2.module.scss";
import Button from "components/core/Button/Button";
import TextInput from "components/core/Forms/TextInput/TextInput";
import Modal from "components/Modal/Modal";
import LoginModal from "components/LoginModal/LoginModal";
import classNames from "classnames";
import Message, { MessageType } from "components/core/Message/Message";
import XLogo from "public/icons/x-logo.svg";

require("lazysizes");

type EmailSubmission = {
  email: string;
};

type LinkItem = {
  text: string;
  href?: string;
  onClick?: (event: any) => void;
  requiredLogin?: boolean;
};

const FooterLink = (props: LinkItem) => {
  if (props.href) {
    return (
      <Link href={props.href}>
        <a className={styles.Footer__link}>{props.text}</a>
      </Link>
    );
  } else if (props.onClick) {
    return (
      <a className={styles.Footer__link} onClick={props.onClick}>
        {props.text}
      </a>
    );
  }
  return <></>;
};

type Props = {
  footerClassName?: string;
  iconClassName?: string;
};

const FooterV2 = (props: Props) => {
  const { user } = useFetchUser();
  const [message, setMessage] = useState<string>();
  const [messageType, setMessageType] = useState<MessageType>();

  // React Hook
  const [modalState, setModalState] = useState({
    visible: false,
  });
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

  const showContactUsModal = (event) => {
    event.preventDefault();
    setModalState({
      visible: true,
    });
  };

  const handleCancel = () => {
    setModalState({
      visible: false,
    });
  };

  const currentYear = new Date().getFullYear();
  // -----------
  // Start Email Submission Code
  // -----------
  const { handleSubmit, register, errors, reset, formState, setValue } =
    useForm<EmailSubmission>();

  const [newsletterSubmission] = useMutationBundle(NewsletterSubmission);

  const emailSubmit = useCallback(
    async (data: EmailSubmission) => {
      const result = await newsletterSubmission({
        variables: {
          email: data.email,
        },
      });

      if (result.data?.newsletterSubmission) {
        setMessage("Your email was successfully submitted!");
        setMessageType(MessageType.SUCCESS);
        setValue("email", "");
      } else {
        setMessage("There was a problem submitting your email");
        setMessageType(MessageType.ERROR);
      }
    },
    [newsletterSubmission]
  );

  const FormMarkup = () => (
    <form onSubmit={handleSubmit(emailSubmit)} className={styles.Footer__form}>
      <label htmlFor="email">Stay Updated</label>
      <div className={styles.Footer__formInputGroup}>
        <TextInput
          options={{
            size: "slim",
            inverse: true,
          }}
          className={styles.Footer__formEmailInput}
          name="email"
          placeholder="Email Address"
          error={errors.email?.message}
          register={register({
            required: "Email is required.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address",
            },
          })}
        />
        <Button className={styles.Footer__subscribeButton} size="medium" submit>
          Subscribe
        </Button>
      </div>
    </form>
  );

  // -----------
  // /End Email Submission Code
  // -----------

  const firstColumnLinks: LinkItem[] = [
    { text: "Perks", href: "/perks" },
    { text: "Brands", href: "/brands" },
    { text: "How It Works", href: "/how-it-works" },
    { text: "Insights", href: "/insights" },
    { text: "FAQs", href: "/faq" },
    { text: "App", href: "/app" },
  ];
  const secondColumnLinks: LinkItem[] = [
    { text: "TiiCKER Perks", href: "/tiicker-perks" },
    { text: "Seattle Perks", href: "/offers/seattle-perks" },
  ];
  const thirdColumnLinks: LinkItem[] = [
    {
      text: "Store",
      href: user ? "/store-login?storeName=tiicker" : undefined,
      onClick: user ? undefined : () => setShowLoginModal(true),
    },
    { text: "News", href: "/news" },
    // { text: "Trends", href: "/insights" },
    { text: "About", href: "/about-us" },
    { text: "Contact", onClick: showContactUsModal },
    { text: "Partner with Us", href: "/partner" },
  ];

  const LinkColumn = (columnProps: { title: string; linkList: LinkItem[] }) => (
    <div className={styles.Footer__block}>
      <p className={styles.Footer__menuHeader}>{columnProps.title}</p>
      <div
        className={`${styles.Footer__linkList} ${
          columnProps.linkList.length > 3 ? styles.Footer__linkList__twoCol : ""
        }`}
      >
        {columnProps.linkList.map((link) => (
          <FooterLink
            key={`footer-link-${link.href}-${link.text}`}
            href={link.href}
            text={link.text}
            onClick={link.onClick}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div className={classNames(styles.Footer, props.footerClassName)}>
      <div className="container">
        <Modal
          visible={modalState.visible}
          width={522}
          onClose={handleCancel}
          className={styles.contactUsModal}
        >
          <ContactForm user={user} afterSubmit={handleCancel} />
        </Modal>
        <LoginModal
          show={showLoginModal}
          showModal={setShowLoginModal}
          description={`To visit the TiiCKER store you must have a free account.`}
        />

        <div className={styles.Footer__columnContainer}>
          <div className={styles.Footer__block}>
            <div className={styles.Footer__logo}>
              <Link href="/">
                <img
                  className={`${styles.footerLogo} lazyload`}
                  data-src="//images.ctfassets.net/bxvspbo2e7cv/BAxaF0uDneOHfwRDoEX5s/3b9021000315a7aa7c738b76cd80e539/tiicker-logo-white.svg"
                  alt="TiiCKER Footer Logo"
                />
              </Link>
            </div>

            <FormMarkup />
            {message !== undefined && messageType !== undefined ? (
              <Message message={message} type={messageType} />
            ) : (
              <></>
            )}
            <div className={styles.Footer__awardLogos}>
              <a href="/news/benzinga-fintech-award-finalists-revealed" >
                <img
                  src="/images/Bezingafinalist.png"
                  alt="BenzingaFinalist Award Logo"
                  className={styles.Footer__awardLogo}
                />
              </a>
                <a href="/news/whats-next-in-loyalty-top-martech-startups">
                  <img
                    src="/images/tiicker_martech_logo.png"
                    alt="MarTech Award Logo"
                    className={styles.Footer__awardLogo}
                  />
                </a>
                <a href="/news/silver-stevie-award-winner-2024-american-business-awards">
                  <img
                    src="/images/ABA24_Silver_Winner 1@3x.png"
                    alt="ABA24 SilverWinner Award Logo"
                    className={styles.Footer__awardLogo}
                  />
                </a>
            </div>
          </div>

          <div className={styles.Footer__secondaryBlocksContainer}>
            <LinkColumn linkList={firstColumnLinks} title="Explore" />
            <LinkColumn linkList={secondColumnLinks} title="Perks" />
            <LinkColumn linkList={thirdColumnLinks} title="Company" />
          </div>
        </div>

        <div className={styles.Footer__socialAndCopy}>
          <div
            className={classNames(
              styles.Footer__socialLinks,
              props.iconClassName
            )}
          >
            <SocialLink
              inverse
              link="https://www.facebook.com/tiickerperks/"
              className={styles.socialIcon}
            >
              <FacebookFilled />
            </SocialLink>
            <SocialLink
              inverse
              link="https://twitter.com/tiickerperks"
              className={styles.socialIcon}
            >
              <XLogo />
            </SocialLink>
            <SocialLink
              inverse
              link="https://www.instagram.com/tiicker/"
              className={styles.socialIcon}
            >
              <InstagramFilled />
            </SocialLink>
            <SocialLink
              inverse
              link="https://www.linkedin.com/company/tiicker/"
              className={styles.socialIcon}
            >
              <LinkedinFilled />
            </SocialLink>
          </div>

          <div className={styles.Footer__copyrightNotice}>
            <p>&copy;{currentYear} TiiCKER. All rights reserved.&nbsp;</p>
            &nbsp;
            <p>
              <Link href="/privacy-policy">Privacy & Terms</Link>&nbsp;
            </p>
            <p>
              <Link href="/disclosures">Disclosure</Link>&nbsp;
            </p>
            <p>
              <Link href="/patents">Patents</Link>&nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterV2;
