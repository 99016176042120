import classNames from "classnames";
import React from "react";
import styles from "./SocialLink.module.scss";

interface Props {
  link: string;
  children: React.ReactNode;
  inverse?: boolean;
  className?: string;
}

const SocialLink = (props: Props) => {
  return (
    <a
      className={classNames(
        styles.SocialLink,
        props.inverse && styles.SocialLink_Inverse,
        props.className
      )}
      target="_blank"
      href={props.link}
      rel="noreferrer"
    >
      {props.children}
    </a>
  );
};

export default SocialLink;
