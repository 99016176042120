import Button from "components/core/Button/Button";
import Modal from "components/Modal/Modal";
import React from "react";
import { useAuth } from "source/hooks";
import styles from "./LoginModal.module.scss";

type Props = {
  show: boolean;
  showModal(state: boolean): void;
  description: string;
  width?: number;
};

const LoginModal = (props: Props) => {
  const auth = useAuth();

  return (
    <Modal
      visible={props.show}
      onClose={() => props.showModal(false)}
      width={450}
      className={styles.modal}
    >
      <div>
        <h3 className={styles.title}>Join for Free</h3>
        <p className={styles.description}>{props.description}</p>
        <div className={styles.buttonContainer}>
          <Button arrow onClick={() => auth.smsSignUp()}>
            Join Now for Free
          </Button>
        </div>
        <p className={styles.signIn}>
          Already a member?{" "}
          <Button type="inline" onClick={() => auth.login()}>
            Sign In
          </Button>
        </p>
      </div>
    </Modal>
  );
};

export default LoginModal;
