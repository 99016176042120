import classNames from "classnames";
import Portal from "components/core/Portal/Portal";
import React, { useEffect, useState } from "react";
import styles from "./Message.module.scss";
import PartnerCheckmark from "public/icons/partner-checkmark.svg";
import Warning from "public/icons/warning.svg";
import QuestionMark from "public/icons/question-mark.svg";

export enum MessageType {
  "SUCCESS",
  "ERROR",
  "INFO",
}

type Props = {
  message: string;
  type: MessageType;
  timeoutMilliseconds?: number;
  onClose?: () => void;
};

const Message = (props: Props) => {
  const [closing, setClosing] = useState<boolean>(false);
  const [close, setClose] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(props.message);

  useEffect(() => {
    if (props.message !== message) {
      setClosing(false);
      setClose(false);
      setMessage(props.message);
      setTimeout(function () {
        closeNotification();
      }, props.timeoutMilliseconds ?? 10000);
    }
  }, [props.message, message]);

  const closeNotification = () => {
    setClosing(true);
    setTimeout(function () {
      setClosing(false);
      setClose(true);
      if (props.onClose) {
        props.onClose();
      }
    }, 290);
  };

  setTimeout(function () {
    closeNotification();
  }, props.timeoutMilliseconds ?? 10000);

  const typeClassName =
    props.type === MessageType.SUCCESS
      ? styles.success
      : props.type === MessageType.ERROR
      ? styles.error
      : props.type === MessageType.INFO
      ? styles.info
      : undefined;

  if (close) {
    return <></>;
  }

  return (
    <Portal containerSelector="#message-container">
      <div className={classNames(styles.notification, typeClassName)}>
        <div className={classNames(styles.message, closing && styles.closing)}>
          {props.type === MessageType.SUCCESS && (
            <PartnerCheckmark className={styles.checkmark} />
          )}
          {props.type === MessageType.ERROR && (
            <Warning className={styles.warning} />
          )}
          {props.type === MessageType.INFO && (
            <QuestionMark className={styles.questionMark} />
          )}
          <p className={styles.messageText}>{props.message}</p>
        </div>
      </div>
    </Portal>
  );
};

export default Message;
