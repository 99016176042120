import React, { useState } from "react";
import styles from "./ContactForm.module.scss";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { ClientUser, useMutationBundle } from "source/hooks";
import { SubmitContactForm } from "$gql/mutations/general/SubmitContactForm.gen";
import TextInput from "components/core/Forms/TextInput/TextInput";
import TextArea from "components/core/Forms/TextArea/TextArea";
import Button from "components/core/Button/Button";
import Message, { MessageType } from "components/core/Message/Message";

type ContactFormData = {
  name: string;
  email: string;
  description: string;
};

type Props = {
  user?: ClientUser | null;
  afterSubmit?: () => void;
};

const ContactForm = (props: Props) => {
  const [message, setMessage] = useState<string>();

  const form = useForm<ContactFormData>({
    defaultValues: {
      name: props.user ? `${props.user.firstName} ${props.user.lastName}` : "",
      email: props.user?.email ?? "",
    },
    mode: "onBlur",
    submitFocusError: true,
  });

  const { handleSubmit, register, errors, formState } = form;
  const [submitContactForm] = useMutationBundle(SubmitContactForm);

  const formSubmit = useCallback(
    async (data: ContactFormData) => {
      const result = await submitContactForm({
        variables: {
          name: data.name,
          email: data.email,
          description: data.description,
        },
      });
      if (result) {
        setMessage("Info received! Thank you for reaching out to us!");
        form.reset({});
        if (props.afterSubmit !== undefined) {
          props.afterSubmit();
        }
      }
    },
    [props.user]
  );

  return (
    <div className={styles.container}>
      {message && <Message message={message} type={MessageType.SUCCESS} />}
      <h3 className={styles.heading}>Contact Us</h3>
      <form onSubmit={handleSubmit(formSubmit)} className={styles.form}>
        <TextInput
          register={register({
            required: "Name is required.",
          })}
          label="Name"
          name="name"
        />
        <TextInput
          register={register({
            required: "Email is required.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address",
            },
          })}
          label="Email"
          name="email"
        />
        <TextArea
          register={register()}
          label="Description"
          name="description"
        />
        <Button type="primary" submit className={styles.button}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;
