import React from "react";
import styles from "./TextArea.module.scss";
import classNames from "classnames";
import InputWrapper, { FormWrapperProps } from "../InputWrapper/InputWrapper";

type RefReturn =
  | string
  | ((instance: HTMLTextAreaElement | null) => void)
  | React.RefObject<HTMLTextAreaElement>
  | null
  | undefined;

type Props = {
  register: RefReturn;
  wrapperClass?: string;
} & FormWrapperProps &
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >;

const TextArea = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { className, ...allOtherProps } = props;

  return (
    <InputWrapper {...allOtherProps} className={props.wrapperClass}>
      <textarea
        {...props}
        name={props.name}
        placeholder={props.placeholder}
        className={classNames(styles.textArea, className)}
        ref={props.register}
      />
    </InputWrapper>
  );
});

export default TextArea;
